import React from "react";
import FooterNew from "./Footernew";

const FooterDemo = () => {
  return (
    <div>
      <FooterNew theme="light" />
    </div>
  );
};

export default FooterDemo;
